import React, { useState, useRef ,useEffect}  from "react";
import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";
import emailjs from '@emailjs/browser';
const ContactThree = () => {
   //ajouter contact      
 
  const [successful, setSuccessful] = useState(false);
  const [message, setMessage] = useState("");
  const form = useRef();
 
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_2zh3ili', 'template_xs42kzv', form.current, 'LKQFjMtJuBP8Y1m9z')
      .then((result) => {
        console.log(result.text);
          setMessage("vos informations on bien été envoyer")
          setSuccessful(true);
          console.log("votre mail a bien ete envoyer ")
      }, (error) => {
          console.log(error.text);
          setMessage("erreur de connexion")
          setSuccessful(false);
      });
  };
 
  return (
    <section className="contact_form_area contact_us section_padding">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="contact-right contact_details">
              <div className="hero-title-with-shape">
                <h4 className="heading_with_border">Contacter nous</h4>
                <h1>Nous offrons un service d'urgence 24/7 à tous nos clients</h1>
              </div>
              <p>
              Adressez nous votre demande de renseignements ou de devis pour vos projets neuf ou rénovation!
              </p>
              <div className="contact_location_box">
                <div className="contact_location_map">
                  <span className="icon-location"></span>
                  <p>
                  183-189 Avenue de Choisy 75013, <br />
                  Paris
                  </p>
                </div>
                <div className="contact_location_map contact_location_call">
                  <span className="icon-contact_call"></span>
                  <p>
                    deco-alliance.com <br />
                    +33 6 67 06 77 89
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="contact-right contact-right-style-2 responsive_mt">
            <form ref={form} onSubmit={sendEmail}>
              {!successful && (
                <div className="">
                  <input
                  className="half_width input_m_right"
                  type="text"
                  name="nom"
                  placeholder="votre nom"
                />
                <input
                  className="half_width"
                  type="text"
                  name="prenom"
                  placeholder="prenom"
                />
                <input 
                  type="tel" 
                  name="telephone"
                 
                  placeholder="votre telephone" />
                <textarea
                  name="type_de_service"
                  id="content"
                  cols="20"
                  rows="5"
                  placeholder="vos besoins"
                ></textarea>
                <button className="btn-yellow" value="submit">
                  VALIDER
                </button>
                </div>
            
                 )}
                  {message && (
                              <div className="form-group">
                                <div
                                  className={
                                    successful
                                      ? "alert alert-success"
                                      : "alert alert-danger"
                                  }
                                  role="alert"
                                >
                                  {message}
                                </div>
                              </div>
                  )}
                
              </form>
             
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactThree;
